import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'

import { baseline_assm_steps, tp_type } from '../../../../variables/baseline_assm.js'
import { followup_assm_steps } from '../../../../variables/followup_assm.js'
import { csq8_questions1, csq8_scale1, csq8_scale2, csq8_scale3,
        csq8_scale4, csq8_scale5, csq8_scale6, csq8_scale7,
        csq8_scale8, csq8_questions1_es, csq8_scale1_es, csq8_scale2_es,
        csq8_scale3_es, csq8_scale4_es, csq8_scale5_es, csq8_scale6_es,
        csq8_scale7_es, csq8_scale8_es  } from '../../../../variables/baseline_csq8_platform.js'

class Csq8Platform extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        timepoint: 0,
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        q5: 0,
        q6: 0,
        q7: 0,
        q8: 0,
        comments: '',
        created: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_participant/get_csq8_platform',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        type_tp: this.props.match.params.type_tp,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'].uid > 0) {
        this.setState({
          type_tp: this.props.match.params.type_tp,
          timepoint: this.props.match.params.timepoint,
          created: result['data'].created,
          q1: result['data'].q1,
          q2: result['data'].q2,
          q3: result['data'].q3,
          q4: result['data'].q4,
          q5: result['data'].q5,
          q6: result['data'].q6,
          q7: result['data'].q7,
          q8: result['data'].q8,
          comments: result['data'].comments,
        })
      }
    })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    var csq_scale = [csq8_scale1, csq8_scale2, csq8_scale3, csq8_scale4,
                     csq8_scale5, csq8_scale6, csq8_scale7, csq8_scale8]
    return (
    <div className="participant1">
        <div className="headerPage3_1 medium-6 columns">
        Client Satisfaction Questionnaire - Platform [{this.findLabelByValue(tp_type, this.props.match.params.type_tp)}]
        </div>
        <div className="medium-6 columns align-right">
        Completed: {this.state.created}
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns">
            { csq8_questions1.map((eachQ, index) =>
              <div className="row" key={'q'+eachQ.value}>
                <div className="small-12 medium-9 columns">{eachQ.value + '. ' + eachQ.label}</div>
                <div className="small-12 medium-3 columns">
                  {this.findLabelByValue(csq_scale[index], this.state['q'+eachQ.value])}
                </div>
                {eachQ.value < 8 && <div className="small-12 columns sep_line1">&nbsp;</div>}
              </div>)}
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row">
                <div className="small-12 columns">
                  Please add any comments or questions in the space below.
                </div>
                <div className="small-12 columns">
                {this.state.comments}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns back_button1">
          <a href={'/admin/participant_chart/'+this.props.match.params.pid+'/2/2'}>Back</a>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Csq8Platform)
