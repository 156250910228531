import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'

import { baseline_assm_steps, yes_no, yes_no_es } from '../../variables/baseline_assm.js'
import { geri_questions, geri_questions_es } from '../../variables/baseline_geri.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Cookies from 'js-cookie'

class GeriAims extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        q5: 0,
        q6: 0,
        q7: 0,
        q8: 0,
        q9: 0,
        q10: 0,
        q11: 0,
        q12: 0,
        q13: 0,
        q14: 0,
        q15: 0,
        q16: 0,
        q17: 0,
        q18: 0,
        q19: 0,
        q19_specify: '',
    }
  }

  componentDidMount() {
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid }
    })
    .then((result) => {
        if(result['data'] !== 'geri_aims') {
          this.props.history.push('/member/registration/'+result['data'].reg_step)
        }
      }
    )
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value,
    })
  }

  validateAllField = () => {
    var chk = true
    var txt_warning = ''
    var arr = ['q1', 'q2', 'q3', 'q4', 'q5', 'q6', 'q7', 'q8', 'q9', 'q10',
               'q11', 'q12', 'q13', 'q14', 'q15', 'q16', 'q17', 'q18', 'q19']
    const lang = Cookies.get('lang_')
    arr.map(each => {
      if(this.state[each] == 0) {
        chk = false
        if(lang=='es') {
          txt_warning = 'Por favor entre una respuesta para todas las preguntas'
        } else {
          txt_warning = 'Please enter a response to all questions.'
        }
        return ''
      }
    })
    if(chk && this.state.q19 == 1 && this.state.q19_specify.trim() == '') {
      if(lang=='es') {
        txt_warning = 'Por favor especifique otro '
      } else {
        txt_warning = 'Please specify other'
      }
    }

    return txt_warning
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var get_validate_msg = this.validateAllField()
    //console.log(get_validate_msg)
    if(get_validate_msg == '') {
      var pid = this.props.authReducer.uid
      axios({
        method: 'post',
        url: connectNode + 'participants/registration/add_geri_aims',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: pid,
          user: this.state,
        }
      })
      .then((result) => {
        var message = ''
        if(result['data'] == pid) {
          window.scrollTo(0, 0)
          this.props.onLoadLocation('/member/registration/womac', 'Thank you for submitting your Geri-AIMS!')
          this.props.history.push('/member/registration/womac')
        } else {
          message = 'Something went wrong. Please try again.'
        }
        if(message !== '') {
          this.setState({
            message: message
          })
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: get_validate_msg,
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    const lang = Cookies.get('lang_')
    const yn_answer = lang == 'es' ? yes_no_es : yes_no
    const geri_q = lang == 'es' ? geri_questions_es : geri_questions
    return (
    <div className="participant1">
        <div className="headerPage3_1">
          New Participant Questionnaires
        </div>
        <div className="small-12 columns stepper">
          <Stepper alternativeLabel nonLinear activeStep={1}>
            {baseline_assm_steps.map(each => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={'st'+each.value} {...stepProps}>
                  <StepLabel {...labelProps}>{each.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { (this.state.message !== '' || this.state.compTxt !== '') &&
          <div className="row margin-bottom1">
            <div className={(this.state.message !== '' && 'small-12 columns warning_msg6')
            || (this.state.compTxt !== '' && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {this.state.message !== '' && <WarningIcon />}
              {this.state.compTxt !== '' && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message || this.state.compTxt }</div>
            </div>
          </div>}
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns headerPage3_2">
              {(lang=='en' && 'Chronic Conditions: Geri-AIMS') || lang=='es' && 'Condiciones Crónicas: Geri-Artritis IMS'}
            </div>
            <div className="small-12 columns">
              {(lang=='en' && 'Is your health currently affected by any of the following medical problems (i.e., do any of the conditions below impact your stress and/or ability to do things)?')
              || lang=='es' && '¿Está su salud afectada actualmente, por cualquiera de los siguientes problemas médicos? ¿Si su respuesta es sí, cuanto afecta el problema con sus actividades? (Favor de marcar la respuesta apropiada.)'}
            </div>
            <div className="small-12 columns">
            { geri_q.map(eachQ =>
              <div className="row" key={'q'+eachQ.value}>
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 medium-8 columns">{eachQ.value + '. ' + eachQ.label}</div>
                {yn_answer.map(each =>
                <div className="small-12 medium-2 columns float_left" key={'q'+eachQ.value+each.value}>
                  <input type="radio" name={'q'+eachQ.value} value={each.value}
                  disabled={this.state.completed_status && 'disabled'}
                  checked={this.state['q'+eachQ.value] == each.value && 'checked'}
                  onChange={(e) => this.handleChange2(e, 'q'+eachQ.value)}/>
                  <span>{each.label}</span>
                </div>)}
              </div>
            )}
            {this.state.q19 == '1' &&
            <div className="row">
              <div className="small-12 medium-4 columns float_right1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                disabled={this.state.completed_status && 'disabled'}
                type="text" value={this.state.q19_specify} className="w_100per"
                InputProps={{ inputProps: { name: "q19_specify", maxLength: 49, placeholder: 'Please specify' }}} />
              </div>
            </div>}
            </div>
          </div>
        </div>
        {!this.state.completed_status &&
        <div className="small-12 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>}
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GeriAims)
