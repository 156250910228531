import React from "react";
import ParticipantList from "../components/admin/participants/participant_list"
import NewParticipant from "../components/admin/participants/new_participant"
import ParticipantChart from "../components/admin/participants/participant_chart/participant_chart"
import GroupSettings from "../components/admin/group_settings/group_settings"
import Group from "../components/admin/group_settings/group"
import EventList from "../components/admin/events/event_list"
import Event from "../components/admin/events/event"
import EventParticipantList from "../components/admin/events/event_participant_list"
import FilePDF from "../components/admin/participants/participant_chart/screening/file_pdf"
import Demographic from "../components/admin/participants/participant_chart/assessments/demographic"
import GeriAims from "../components/admin/participants/participant_chart/assessments/geri_aims"
import Womac from "../components/admin/participants/participant_chart/assessments/womac"
import Utilization from "../components/admin/participants/participant_chart/assessments/utilization"
import PromisPF from "../components/admin/participants/participant_chart/assessments/promis_pf"
import PromisSi from "../components/admin/participants/participant_chart/assessments/promis_si"
import FallHistory from "../components/admin/participants/participant_chart/assessments/fall_history"
import ExerciseConfidence from "../components/admin/participants/participant_chart/assessments/exercise_confidence"
import Who5 from "../components/admin/participants/participant_chart/assessments/who5"
import Phq9 from "../components/admin/participants/participant_chart/assessments/phq9"
import Gad7 from "../components/admin/participants/participant_chart/assessments/gad7"
import Motivation from "../components/admin/participants/participant_chart/assessments/motivation"
import Csq8 from "../components/admin/participants/participant_chart/assessments/csq8"
import Csq8Program from "../components/admin/participants/participant_chart/assessments/csq8_program"
import Csq8Platform from "../components/admin/participants/participant_chart/assessments/csq8_platform"
import ExerciseClassFollowUp from "../components/admin/participants/participant_chart/assessments/exercise_class_follow_up"
import ExitInterview from "../components/admin/participants/participant_chart/assessments/exit_interview"
import NiaCroms from "../components/admin/nia/nia_croms"
import ReportList from "../components/admin/reports/report_list"
import ExerciseLog from "../components/admin/reports/exercise_log"
import EditStatus from "../components/admin/participants/participant_chart/edit_status"
import ExerciseLogDetail from "../components/admin/participants/participant_chart/exercise/exercise_log_detail"
import TopicList from "../components/admin/forum/topic_list"
import Topic from "../components/admin/forum/topic"
import NewForum from "../components/admin/forum/new_forum"
import FocusBlog from "../components/admin/focus_blog/focus_blog"
import FrmCommitment from "../components/members/participant_contract/frm_commitment"
import FrmIndividualPlan from "../components/members/participant_contract/frm_individual_plan"
import FrmRevisiting from "../components/members/participant_contract/frm_revisiting"
import Settings from "../components/admin/participants/participant_chart/settings"

const ADMIN_ROUTES = [
  {   path: "/admin/participant_list",
      key: "participant_list",
      title: "Participant list",
      exact: true,
      requireAuth: true,
      component: () => <ParticipantList />,
      showtitle: 'true',
  },
  {   path: "/admin/add_participant",
      key: "add_participant",
      title: "New Participant",
      exact: true,
      requireAuth: true,
      component: () => <NewParticipant />,
      showtitle: 'true',
  },
  {   path: "/admin/participant_chart/:pid/:maintab/:subtab",
      key: "participant_chart",
      title: "Participant Chart",
      exact: true,
      requireAuth: true,
      component: () => <ParticipantChart />,
      showtitle: 'true',
  },
  {   path: "/admin/group_setting",
      key: "group_setting",
      title: "Group Setting",
      exact: true,
      requireAuth: true,
      component: () => <GroupSettings />,
      showtitle: 'true',
  },
  {   path: "/admin/group_setting/group/:gid",
      key: "group",
      title: "Group",
      exact: true,
      requireAuth: true,
      component: () => <Group />,
      showtitle: 'true',
  },
  {   path: "/admin/events/event_list",
      key: "event_list",
      title: "Event List",
      exact: true,
      requireAuth: true,
      component: () => <EventList />,
      showtitle: 'true',
  },
  {   path: "/admin/events/event/:cid",
      key: "event",
      title: "Event",
      exact: true,
      requireAuth: true,
      component: () => <Event />,
      showtitle: 'true',
  },
  {   path: "/admin/events/participant_list/:gid/:cid",
      key: "participant_list",
      title: "Participant List",
      exact: true,
      requireAuth: true,
      component: () => <EventParticipantList />,
      showtitle: 'true',
  },
  {   path: "/admin/participant_chart/file/:fid",
      key: "participant_chart_file",
      title: "File",
      exact: true,
      requireAuth: true,
      component: () => <FilePDF />,
      showtitle: 'true',
  },
  // Assessments //
  {   path: "/admin/assessments/demographic/:pid/:type_tp/:timepoint",
      key: "demographic",
      title: "Demographic",
      exact: true,
      requireAuth: true,
      component: () => <Demographic />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/geri_aims/:pid/:type_tp/:timepoint",
      key: "geri_aims",
      title: "Geri-AIMS",
      exact: true,
      requireAuth: true,
      component: () => <GeriAims />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/womac/:pid/:type_tp/:timepoint",
      key: "womac",
      title: "WOMAC",
      exact: true,
      requireAuth: true,
      component: () => <Womac />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/utilization/:pid/:type_tp/:timepoint",
      key: "utilization",
      title: "Health Care Utilization",
      exact: true,
      requireAuth: true,
      component: () => <Utilization />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/promis_pf/:pid/:type_tp/:timepoint",
      key: "utilization",
      title: "PROMIS Physical Function",
      exact: true,
      requireAuth: true,
      component: () => <PromisPF />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/fall_history/:pid/:type_tp/:timepoint",
      key: "fall_history",
      title: "Fall History",
      exact: true,
      requireAuth: true,
      component: () => <FallHistory />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/exercise_confidence/:pid/:type_tp/:timepoint",
      key: "exercise_confidence",
      title: "Exercise Confidence",
      exact: true,
      requireAuth: true,
      component: () => <ExerciseConfidence />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/who5/:pid/:type_tp/:timepoint",
      key: "who5",
      title: "WHO-5",
      exact: true,
      requireAuth: true,
      component: () => <Who5 />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/phq9/:pid/:type_tp/:timepoint",
      key: "phq9",
      title: "PHQ-9",
      exact: true,
      requireAuth: true,
      component: () => <Phq9 />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/gad7/:pid/:type_tp/:timepoint",
      key: "gad7",
      title: "GAD-7",
      exact: true,
      requireAuth: true,
      component: () => <Gad7 />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/promis_si/:pid/:type_tp/:timepoint",
      key: "promis_si",
      title: "Social Isolation",
      exact: true,
      requireAuth: true,
      component: () => <PromisSi />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/motivation/:pid/:type_tp/:timepoint",
      key: "motivation",
      title: "Motivation",
      exact: true,
      requireAuth: true,
      component: () => <Motivation />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/csq8/:pid/:type_tp/:timepoint",
      key: "csq8",
      title: "Csq8",
      exact: true,
      requireAuth: true,
      component: () => <Csq8 />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/csq8_platform/:pid/:type_tp/:timepoint",
      key: "csq8_platform",
      title: "Csq8 Platform",
      exact: true,
      requireAuth: true,
      component: () => <Csq8Platform />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/csq8_program/:pid/:type_tp/:timepoint",
      key: "csq8_program",
      title: "Csq8 Program",
      exact: true,
      requireAuth: true,
      component: () => <Csq8Program />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/exercise_class_follow_up/:pid/:type_tp/:timepoint",
      key: "exercise_class_follow_up",
      title: "Exercise Class Follow Up",
      exact: true,
      requireAuth: true,
      component: () => <ExerciseClassFollowUp />,
      showtitle: 'true',
  },
  {   path: "/admin/assessments/exit_interview/:pid/:type_tp/:timepoint",
      key: "exit_interview",
      title: "Exit Interview",
      exact: true,
      requireAuth: true,
      component: () => <ExitInterview />,
      showtitle: 'true',
  },
  {   path: "/admin/nia_croms",
      key: "nia_croms",
      title: "NIA CROMS",
      exact: true,
      requireAuth: true,
      component: () => <NiaCroms />,
      showtitle: 'true',
  },
  {   path: "/admin/report_list",
      key: "report_list",
      title: "Reports",
      exact: true,
      requireAuth: true,
      component: () => <ReportList />,
      showtitle: 'true',
  },
  {   path: "/admin/report/exercise_log",
      key: "exercise_log",
      title: "Report",
      exact: true,
      requireAuth: true,
      component: () => <ExerciseLog />,
      showtitle: 'true',
  },
  {   path: "/admin/exercise/:pid/:month/:day/:year",
      key: "exercise_log",
      title: "Report",
      exact: true,
      requireAuth: true,
      component: () => <ExerciseLogDetail />,
      showtitle: 'true',
  },
  {   path: "/admin/participant_chart/edit_status/:pid",
      key: "edit_status",
      title: "Edit Status",
      exact: true,
      requireAuth: true,
      component: () => <EditStatus />,
      showtitle: 'true',
  },
  {   path: "/admin/participant_chart/change_password/:pid",
      key: "change_password",
      title: "Change Password",
      exact: true,
      requireAuth: true,
      component: () => <Settings />,
      showtitle: 'true',
  },
  {   path: "/admin/forum/topic_list",
      key: "topic_list",
      title: "Forum",
      exact: true,
      requireAuth: true,
      component: () => <TopicList />,
      showtitle: 'true',
  },
  {   path: "/admin/forum/topic/:tid",
      key: "topic",
      title: "Forum",
      exact: true,
      requireAuth: true,
      component: () => <Topic />,
      showtitle: 'true',
  },
  {   path: "/admin/forum/new_forum",
      key: "new_forum",
      title: "Forum",
      exact: true,
      requireAuth: true,
      component: () => <NewForum />,
      showtitle: 'true',
  },
  {   path: "/admin/focus_blog/focus_blog/:gid",
      key: "focus_blog",
      title: "Focus Blog",
      exact: true,
      requireAuth: true,
      component: () => <FocusBlog />,
      showtitle: 'true',
  },
  {   path: "/admin/participant_contract/:pid/frm_commitment",
      key: "frm_commitment",
      title: "Commitment to class and practice sessions",
      exact: true,
      requireAuth: true,
      component: () => <FrmCommitment />,
      showtitle: 'true',
  },
  {   path: "/admin/participant_contract/:pid/frm_individual_plan",
      key: "frm_individual_plan",
      title: "Individual plan for maintaining an active lifestyle",
      exact: true,
      requireAuth: true,
      component: () => <FrmIndividualPlan />,
      showtitle: 'true',
  },
  {   path: "/admin/participant_contract/:pid/frm_visiting",
      key: "frm_visiting",
      title: "Revisiting your goals from the beginning of the program",
      exact: true,
      requireAuth: true,
      component: () => <FrmRevisiting />,
      showtitle: 'true',
  },
];

export default ADMIN_ROUTES;
