import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'

import { baseline_assm_steps } from '../../variables/baseline_assm.js'
import { followup_assm_steps, followup_assm_steps_3m } from '../../variables/followup_assm.js'
import { questions1, scale1, scale2, scale3,
         scale4, scale5, scale6, scale7, scale7_1, scale8, scale9, scale10, scale11,
         questions1_es, scale1_es, scale2_es, questions2, scale21, scale22, scale23,
         scale24, scale25, scale26, scale27,
         scale3_es, scale4_es, scale5_es, scale6_es,
         scale7_es, scale7_1_es, scale8_es  } from '../../variables/baseline_exit.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Cookies from 'js-cookie'

class ExitInterview extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        timepoint: 0,
        q1: 0,
        q2: 0,
        q3_1: 0,
        q3_2: 0,
        q3_3: 0,
        q3_4: 0,
        q3_5: 0,
        q3_6: 0,
        q3_7: 0,
        q3_8: 0,
        q3_8_other: '',
        q4: 0,
        q5: 0,
        q6_1: 0,
        q6_2: 0,
        q6_3: 0,
        q6_4: 0,
        q6_5: 0,
        q6_6: 0,
        q6_7: 0,
        q6_7_other: '',
        q7: 0,
        q7_1: 0,
        q7_1_other: '',
        q8: 0,
        q9: 0,
        q10_1: 0,
        q10_2: 0,
        q10_3: 0,
        q10_4: 0,
        q10_5: 0,
        q10_6: 0,
        q10_6_other: '',
        q10_7: 0,
        q11_1: 0,
        q11_2: 0,
        q11_3: 0,
        q11_4: 0,
        q11_5: 0,
        q11_6: 0,
        q11_6_other: '',
        q11_7: 0,
        q21_1: 0,
        q21_2: 0,
        q21_3: 0,
        q21_4: 0,
        q21_5: 0,
        q21_5_other: '',
        q22: 0,
        q23: 0,
        q24: 0,
        q25: 0,
        q26: 0,
        q27: 0,
        comments: '',
    }
  }

  componentDidMount() {
    this.getProcess()
  }

  getProcess = () => {
    var timepoint = 0
    if(this.props.match.params.timepoint > 0) {
      timepoint = this.props.match.params.timepoint
    }
    var get_uri = this.props.location.pathname
    var sep_uri = get_uri.split("/")
    //console.log(sep_uri[2])
    if(sep_uri[2] == 'followup') {
      axios({
        method: 'post',
        url: connectNode + 'participants/followup/get_followup_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.authReducer.uid,
          assm: 'exit',
          tp_type: this.props.match.params.tp_type,
          timepoint: timepoint,
        }
      })
      .then((result) => {
        console.log(result['data'])
        if(result['data'][1].assm_val.id > 0) {
          const get_val = result['data'][1].assm_val
          this.setState({
            completed_status: true,
            timepoint: get_val.timepoint,
            q1: get_val.q1,
            q2: get_val.q2,
            q3_1: get_val.q3_1,
            q3_2: get_val.q3_2,
            q3_3: get_val.q3_3,
            q3_4: get_val.q3_4,
            q3_5: get_val.q3_5,
            q3_6: get_val.q3_6,
            q3_7: get_val.q3_7,
            q3_8: get_val.q3_8,
            q3_8_other: get_val.q3_8_other,
            q4: get_val.q4,
            q5: get_val.q5,
            q6_1: get_val.q6_1,
            q6_2: get_val.q6_,
            q6_3: get_val.q6_3,
            q6_4: get_val.q6_4,
            q6_5: get_val.q6_5,
            q6_6: get_val.q6_6,
            q6_7: get_val.q6_7,
            q6_7_other: get_val.q6_7_other,
            q7: get_val.q7,
            q7_1: get_val.q7_1,
            q7_1_other: get_val.q7_1_other,
            q8: get_val.q8,
            q9: get_val.q9,
            q10_1: get_val.q10_1,
            q10_2: get_val.q10_2,
            q10_3: get_val.q10_3,
            q10_4: get_val.q10_4,
            q10_5: get_val.q10_5,
            q10_6: get_val.q10_6,
            q10_6_other: get_val.q10_6_other,
            q10_7: get_val.q10_7,
            q11_1: get_val.q11_1,
            q11_2: get_val.q11_2,
            q11_3: get_val.q11_3,
            q11_4: get_val.q11_4,
            q11_5: get_val.q11_5,
            q11_6: get_val.q11_6,
            q11_6_other: get_val.q11_6_other,
            q11_7: get_val.q11_7,
            q21_1: get_val.q21_1,
            q21_2: get_val.q21_2,
            q21_3: get_val.q21_3,
            q21_4: get_val.q21_4,
            q21_5: get_val.q21_5,
            q21_5_other: get_val.q21_5_other,
            q22: get_val.q22,
            q23: get_val.q23,
            q24: get_val.q24,
            q25: get_val.q25,
            q26: get_val.q26,
            q27: get_val.q27,
            comments: get_val.comments,
          })
        }
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value,
    })
  }

  handleChange3 = (e, name) => {
    if(e.target.checked) {
      this.setState({
        [name]: e.target.value,
      })
    } else {
      this.setState({
        [name]: 0,
      })
    }
  }

  validateAllField = () => {
    var chk = true
    var txt_warning = ''
    var arr = ['q1', 'q2', 'q4', 'q5', 'q7', 'q8', 'q9', 'q22'
               , 'q23', 'q24', 'q25', 'q26', 'q27']
    arr.map(each => {
      if(this.state[each] == 0) {
        chk = false
        const lang = Cookies.get('lang_')
        if(lang=='es') {
          txt_warning = 'Por favor entre una respuesta para todas las preguntas'
        } else {
          txt_warning = 'Please enter a response to all questions.'
        }
        return ''
      }
    })

    return txt_warning
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var tp_type = this.props.match.params.tp_type
    var timepoint = this.props.match.params.timepoint
    if(this.state.completed_status) {
      this.props.onLoadLocation('/member/followup/completed/'+tp_type+'/'+timepoint, '')
      this.props.history.push('/member/followup/completed/'+tp_type+'/'+timepoint)
    } else {
      var get_validate_msg = this.validateAllField()
      //console.log(get_validate_msg)
      if(get_validate_msg == '') {
        var pid = this.props.authReducer.uid
        var get_uri = this.props.location.pathname
        var sep_uri = get_uri.split("/")
        var get_steps = []
        var current_step = 0
        if(sep_uri[2] == 'followup') {
          axios({
            method: 'post',
            url: connectNode + 'participants/followup/add_exit_interview',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
              pid: pid,
              tp_type: tp_type,
              timepoint: timepoint,
              user: this.state,
            }
          })
          .then((result) => {
            var message = ''
            if(result['data'] == pid) {
              window.scrollTo(0, 0)
              this.props.onLoadLocation('/member/followup/completed/'+tp_type+'/'+timepoint, 'Thank you for submitting your CSQ-8!')
              this.props.history.push('/member/followup/completed/'+tp_type+'/'+timepoint)
            } else {
              message = 'Something went wrong. Please try again.'
            }
            if(message !== '') {
              this.setState({
                message: message
              })
              window.scrollTo(0, 0)
            }
          })
        }
      } else {
        this.setState({
          message: get_validate_msg,
        })
        window.scrollTo(0, 0)
      }
    }
  }

  render() {
    var get_uri = this.props.location.pathname
    var sep_uri = get_uri.split("/")
    var get_steps = []
    var current_step = 0
    if(sep_uri[2] == 'followup') {
      if(sep_uri[4] == 'm' && sep_uri[5] == '3')
      get_steps = followup_assm_steps_3m
      current_step = 9
    }

    const lang = Cookies.get('lang_')
    const ques1 = lang == 'es' ? questions1_es : questions1
    const csq_scale = lang == 'es' ? [scale1_es, scale2, scale3_es,
                     scale4_es, scale5_es, scale6_es, scale7_es,
                     scale8_es] : [scale1, scale2, scale3,
                     scale4, scale5, scale6, scale7, scale8]
    return (
    <div className="participant1 exit_interview">
        <div className="headerPage3_1">
        {sep_uri[2] == 'followup' && 'Assessments: ' + sep_uri[5] }
        {sep_uri[2] == 'followup' && sep_uri[4] == 'w' && ' weeks' }
        {sep_uri[2] == 'followup' && sep_uri[4] == 'm' && ' months' }
        </div>
        <div className="small-12 columns stepper">
          <Stepper alternativeLabel nonLinear activeStep={current_step}>
            {get_steps.map(each => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={'st'+each.value} {...stepProps}>
                  <StepLabel {...labelProps}>{each.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { (this.state.message !== '' || this.state.compTxt !== '') &&
          <div className="row margin-bottom1">
            <div className={(this.state.message !== '' && 'small-12 columns warning_msg6')
            || (this.state.compTxt !== '' && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {this.state.message !== '' && <WarningIcon />}
              {this.state.compTxt !== '' && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message || this.state.compTxt }</div>
            </div>
          </div>}
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns headerPage3_2">
              {(lang=='en' && 'Exit Questionnaire') || (lang=='es' && 'Exit Questionnaire')}
            </div>
            <div className="small-12 columns">
              {(lang=='en' && 'The following questions relate to the exercise program that you participated in during this study. Please answer all of the questions. Your feedback will help to make this platform better for others. Thank you!')
              || (lang=='es' && 'The following questions relate to the exercise program that you participated in during this study. Please answer all of the questions. Your feedback will help to make this platform better for others. Thank you!')}
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns">
              <div className="row" >
                <div className="small-12 columns">1. {ques1[0].label}</div>
                {scale1.map(each =>
                <div key={'sc1_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q1_'+each.value}>
                    <input type="radio" name="q1" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state.q1 == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q1')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row" >
                <div className="small-12 columns">2. {ques1[1].label}</div>
                {scale2.map(each =>
                <div key={'sc2_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q2_'+each.value}>
                    <input type="radio" name="q2" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q2'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q2')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row" >
                <div className="small-12 columns">3. {ques1[2].label}</div>
                {scale3.map(each =>
                <div key={'sc3_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q3_'+each.value}>
                    <input type="checkbox" name={'q3_'+each.value} value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q3_'+each.value] == each.value && 'checked'}
                    onChange={(e) => this.handleChange3(e, 'q3_'+each.value)}/>
                    <span>{each.label}</span>
                    {each.value == 8 && this.state.q3_8 == 8 &&
                    <div className="top_space1"><TextField onChange={this.handleChange} variant="outlined"
                    disabled={this.state.completed_status && 'disabled'} name="q3_8_other"
                    type="text" value={this.state.q3_8_other} className="w_80per"
                    InputProps={{ inputProps: { name: "q3_8_other", maxLength: 250, placeholder: 'Please specify' }}} /></div>}
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row" >
                <div className="small-12 columns">
                  <i>The following questions relate to the online platform you used during this study. Please answer all of the questions. Your feedback will help to make this platform better for others. Thank you!</i>
                </div>
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">4. {ques1[3].label}</div>
                {scale4.map(each =>
                <div key={'sc4_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q4_'+each.value}>
                    <input type="radio" name="q4" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q4'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q4')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">5. {ques1[4].label}</div>
                {scale5.map(each =>
                <div key={'sc5_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q5_'+each.value}>
                    <input type="radio" name="q5" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q5'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q5')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              {(this.state.q5 == 3 || this.state.q5 == 4) &&
              <>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row" >
                <div className="small-12 columns">6. {ques1[5].label}</div>
                {scale6.map(each =>
                <div>
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q6_'+each.value}>
                    <input type="checkbox" name={'q6_'+each.value} value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q6_'+each.value] == each.value && 'checked'}
                    onChange={(e) => this.handleChange3(e, 'q6_'+each.value)}/>
                    <span>{each.label}</span>
                    {each.value == 7 && this.state.q6_7 == 7 &&
                    <div className="top_space1"><TextField onChange={this.handleChange} variant="outlined"
                    disabled={this.state.completed_status && 'disabled'} name="q6_7_other"
                    type="text" value={this.state.q6_7_other} className="w_80per"
                    InputProps={{ inputProps: { name: "q6_7_other", maxLength: 250, placeholder: 'Please specify' }}} /></div>}
                  </div>
                </div>)}
              </div>
              </>}
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">7. {ques1[6].label}</div>
                {scale7.map(each =>
                <div key={'sc7_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q7_'+each.value}>
                    <input type="radio" name="q7" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q7'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q7')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              {this.state.q7 == 1 &&
              <><div className="row top_space1" >
                  <div className="small-12 columns">What would feel like the right amount of support to use the platform?</div>
                  {scale7_1.map(each =>
                  <div key={'sc71_'+each.value} >
                    <div className="show-for-medium medium-1 columns">&nbsp;</div>
                    <div className="small-12 medium-11 columns top_space2" key={'q7_1'+each.value}>
                      <input type="radio" name="q7_1" value={each.value}
                      disabled={this.state.completed_status && 'disabled'} className="rad1"
                      checked={this.state['q7_1'] == each.value && 'checked'}
                      onChange={(e) => this.handleChange2(e, 'q7_1')}/>
                      <span>{each.label}</span>
                      {each.value == 4 && this.state.q7_1 == 4 &&
                      <div className="top_space1"><TextField onChange={this.handleChange} variant="outlined"
                      disabled={this.state.completed_status && 'disabled'} name="q7_1_other"
                      type="text" value={this.state.q7_1_other} className="w_80per"
                      InputProps={{ inputProps: { name: "q7_1_other", maxLength: 250, placeholder: 'Please specify' }}} /></div>}
                    </div>
                  </div>)}
                </div>
                <div className="small-12 columns sep_line1">&nbsp;</div>
              </>}

              <div className="row top_space1" >
                <div className="small-12 columns">8. {ques1[7].label}</div>
                {scale8.map(each =>
                <div key={'sc8_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q8_'+each.value}>
                    <input type="radio" name="q8" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q8'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q8')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">9. {ques1[8].label}</div>
                {scale9.map(each =>
                <div key={'sc9_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q9_'+each.value}>
                    <input type="radio" name="q9" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q9'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q9')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row" >
                <div className="small-12 columns">10. {ques1[9].label}</div>
                {scale10.map(each =>
                <div key={'sc10_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q10_'+each.value}>
                    <input type="checkbox" name={'q10_'+each.value} value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q10_'+each.value] == each.value && 'checked'}
                    onChange={(e) => this.handleChange3(e, 'q10_'+each.value)}/>
                    <span>{each.label}</span>
                    {each.value == 6 && this.state.q10_6 == 6 &&
                    <div className="top_space1"><TextField onChange={this.handleChange} variant="outlined"
                    disabled={this.state.completed_status && 'disabled'} name="q10_6_other"
                    type="text" value={this.state.q10_6_other} className="w_80per"
                    InputProps={{ inputProps: { name: "q10_6_other", maxLength: 250, placeholder: 'Please specify' }}} /></div>}
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row" >
                <div className="small-12 columns">11. {ques1[10].label}</div>
                {scale11.map(each =>
                <div key={'sc11_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q11_'+each.value}>
                    <input type="checkbox" name={'q11_'+each.value} value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q11_'+each.value] == each.value && 'checked'}
                    onChange={(e) => this.handleChange3(e, 'q11_'+each.value)}/>
                    <span>{each.label}</span>
                    {each.value == 6 && this.state.q11_6 == 6 &&
                    <div className="top_space1"><TextField onChange={this.handleChange} variant="outlined"
                    disabled={this.state.completed_status && 'disabled'} name="q11_6_other"
                    type="text" value={this.state.q11_6_other} className="w_80per"
                    InputProps={{ inputProps: { name: "q11_6_other", maxLength: 250, placeholder: 'Please specify' }}} /></div>}
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row" >
                <div className="small-12 columns bold">Technology</div>
                <div className="small-12 columns top_space1">1. {questions2[0].label}</div>
                {scale21.map(each =>
                <div key={'sc21_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q21_'+each.value}>
                    <input type="checkbox" name={'q21_'+each.value} value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q21_'+each.value] == each.value && 'checked'}
                    onChange={(e) => this.handleChange3(e, 'q21_'+each.value)}/>
                    <span>{each.label}</span>
                    {each.value == 5 && this.state.q21_5 == 5 &&
                    <div className="top_space1"><TextField onChange={this.handleChange} variant="outlined"
                    disabled={this.state.completed_status && 'disabled'} name="q21_5_other"
                    type="text" value={this.state.q21_5_other} className="w_80per"
                    InputProps={{ inputProps: { name: "q21_5_other", maxLength: 250, placeholder: 'Please specify' }}} /></div>}
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">2. {questions2[1].label}</div>
                {scale22.map(each =>
                <div key={'sc22_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q22_'+each.value}>
                    <input type="radio" name="q22" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q22'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q22')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">3. {questions2[2].label}</div>
                {scale22.map(each =>
                <div key={'sc221_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q23_'+each.value}>
                    <input type="radio" name="q23" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q23'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q23')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">4. {questions2[3].label}</div>
                {scale22.map(each =>
                <div key={'sc223_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q24_'+each.value}>
                    <input type="radio" name="q24" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q24'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q24')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">5. {questions2[4].label}</div>
                {scale22.map(each =>
                <div key={'sc224_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q25_'+each.value}>
                    <input type="radio" name="q25" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q25'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q25')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">6. {questions2[5].label}</div>
                {scale22.map(each =>
                <div key={'sc225_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q26_'+each.value}>
                    <input type="radio" name="q26" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q26'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q26')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">7. {questions2[6].label}</div>
                {scale22.map(each =>
                <div key={'sc226_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q27_'+each.value}>
                    <input type="radio" name="q27" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q27'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q27')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>

              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="small-12 columns">
                What comments, feedback do you have about the technology?
              </div>
              <div className="small-12 columns top_space1">
              <TextField onChange={this.handleChange} variant="outlined"
              disabled={this.state.completed_status && 'disabled'} name="comments"
              type="text" value={this.state.comments} className="w_80per"
              InputProps={{ inputProps: { name: "comments", maxLength: 250, placeholder: 'Comment' }}} />
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
             {!this.state.completed_status && 'Submit'}
             {this.state.completed_status && 'Next'}
          </Button>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ExitInterview)
