export const efu_questions1 = [
  {
    label: 'How would you rate the quality of the online platform?',
    value: 1,
  }, {
    label: 'Did you get what you wanted from the online platform?',
    value: 2,
  }, {
    label: 'To what extent has our online platform met your needs?',
    value: 3,
  }, {
    label: 'If a friend were in need of similar help with their exercise, would you recommend our online platform to him or her?',
    value: 4,
  }, {
    label: "How satisfied are you with the amount of help that you received from the online platform?",
    value: 5,
  }, {
    label: 'Has the online platform helped you deal with your exercise more effectively?',
    value: 6,
  }, {
    label: 'In an overall, general sense, how satisfied are you with the online platform?',
    value: 7,
  }, {
    label: 'If you were to seek help again with exercise, would you use our online platform?',
    value: 8,
  },
]

export const csq8_questions1_es = [
  {
    label: '¿Cómo evaluaría la calidad de los servicios que ha recibido?',
    value: 1,
  }, {
    label: '¿Recibió la clase de servicio que usted requería?',
    value: 2,
  }, {
    label: '¿Hasta que punto ha ayudado nuestro programa a solucionar sus problemas?',
    value: 3,
  }, {
    label: '¿Si un/a amigo/a estuviera en necesidad de ayuda similar, le recomendaría nuestro programa?',
    value: 4,
  }, {
    label: "¿Cómo de satisfecho/a esta usted con la cantidad de ayuda que ha recibido?",
    value: 5,
  }, {
    label: '¿Los servicios que ha recibido le han ayudado a enfrentarse mejor a sus problemas?',
    value: 6,
  }, {
    label: '¿En general, cómo de satisfecho/a está usted con los servicios que ha recibido?',
    value: 7,
  }, {
    label: '¿Si necesitara ayuda otra vez volvería a nuestro programa?',
    value: 8,
  },
]

export const ecfu_scale1 = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  },
]

export const ecfu_scale1_es = [
  {
    label: 'Excelente',
    value: 1,
  }, {
    label: 'Buena',
    value: 2,
  },
]

export const ecfu_scale2 = [
  {
    label: 'Pilates',
    value: 1,
  }, {
    label: 'Yoga',
    value: 2,
  }, {
    label: 'Zumba',
    value: 3,
  }, {
    label: 'Cycling',
    value: 4,
  }, {
    label: 'Barre',
    value: 5,
  }, {
    label: 'Running',
    value: 6,
  }, {
    label: 'Swimming/Water aerobics',
    value: 7,
  }, {
    label: 'Tai Chi',
    value: 8,
  }, {
    label: 'Martial arts',
    value: 9,
  },  {
    label: 'Weight training/strength classes',
    value: 10,
  }, {
    label: 'Stretching',
    value: 11,
  },  {
    label: 'Other',
    value: 12,
  },
]

export const csq8_scale2_es = [
  {
    label: 'No definitivamente',
    value: 1,
  }, {
    label: 'En muy pocos casos',
    value: 2,
  }, {
    label: 'Si en general',
    value: 3,
  }, {
    label: 'Si definitivamente',
    value: 4,
  },
]

export const csq8_scale3 = [
  {
    label: 'Almost all of my needs have been met',
    value: 4,
  }, {
    label: 'Most of my needs have been met',
    value: 3,
  }, {
    label: 'Only a few of my needs have been met',
    value: 2,
  }, {
    label: 'None of my needs have been met',
    value: 1,
  },
]

export const csq8_scale3_es = [
  {
    label: 'En casi todos',
    value: 4,
  }, {
    label: 'En la mayor parte',
    value: 3,
  }, {
    label: 'Solo en algunos',
    value: 2,
  }, {
    label: 'En ninguno',
    value: 1,
  },
]

export const csq8_scale4 = [
  {
    label: 'No, definitely not',
    value: 1,
  }, {
    label: 'No, I don’t think so',
    value: 2,
  }, {
    label: 'Yes, I think so',
    value: 3,
  }, {
    label: 'Yes, definitely',
    value: 4,
  },
]

export const csq8_scale4_es = [
  {
    label: 'No definitivamente',
    value: 1,
  }, {
    label: 'No , creo que no',
    value: 2,
  }, {
    label: 'Si, creo que si',
    value: 3,
  }, {
    label: 'Si definitivamente',
    value: 4,
  },
]

export const csq8_scale5 = [
  {
    label: 'Quite dissatisfied',
    value: 1,
  }, {
    label: 'Indifferent or mildly dissatisfied',
    value: 2,
  }, {
    label: 'Mostly satisfied',
    value: 3,
  }, {
    label: 'Very satisfied',
    value: 4,
  },
]

export const csq8_scale5_es = [
  {
    label: 'Nada satisfecho/a',
    value: 1,
  }, {
    label: 'Indiferente o moderadamente no satisfecho/a',
    value: 2,
  }, {
    label: 'Moderadamente satisfecho/a',
    value: 3,
  }, {
    label: 'Muy satisfecho/a',
    value: 4,
  },
]

export const csq8_scale6 = [
  {
    label: 'Yes, it helped a great deal',
    value: 4,
  }, {
    label: 'Yes, it helped somewhat',
    value: 3,
  }, {
    label: 'No, it really didn’t help',
    value: 2,
  }, {
    label: 'No, it seemed to make things worse',
    value: 1,
  },
]

export const csq8_scale6_es = [
  {
    label: 'Si me ayudaron mucho',
    value: 4,
  }, {
    label: 'Si me ayudaron algo',
    value: 3,
  }, {
    label: 'No realmente no me ayudaron',
    value: 2,
  }, {
    label: 'No parecían poner las cosas peor',
    value: 1,
  },
]

export const csq8_scale7 = [
  {
    label: 'Very satisfied',
    value: 4,
  }, {
    label: 'Mostly satisfied',
    value: 3,
  }, {
    label: 'Indifferent of mildly dissatisfied',
    value: 2,
  }, {
    label: 'Quite dissatisfied',
    value: 1,
  },
]

export const csq8_scale7_es = [
  {
    label: 'Muy satisfecho/a',
    value: 4,
  }, {
    label: 'Moderadamente satisfecho/a',
    value: 3,
  }, {
    label: 'Algo insatisfecho/a',
    value: 2,
  }, {
    label: 'Muy Insatisfecho/a',
    value: 1,
  },
]

export const csq8_scale8 = [
  {
    label: 'No, definitely not',
    value: 1,
  }, {
    label: 'No, I don’t think so',
    value: 2,
  }, {
    label: 'Yes, I think so',
    value: 3,
  }, {
    label: 'Yes, definitely',
    value: 4,
  },
]

export const csq8_scale8_es = [
  {
    label: 'No definitivamente',
    value: 1,
  }, {
    label: 'No posiblemente',
    value: 2,
  }, {
    label: 'Si creo que si',
    value: 3,
  }, {
    label: 'Si con seguridad',
    value: 4,
  },
]
