import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/admin/assm.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { file_category } from '../../../../variables/registration.js'
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'

class Week8 extends Component {
  constructor() {
    super()
    this.state = {
      pf_time: '',
      womac_time: '',
      ec_time: '',
      who5_time: '',
      phq9_time: '',
      gad7_time: '',
      si_time: '',
      csq8_time: '',
      csq8_platform_time: '',
      csq8_program_time: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/get_assm_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        timepoint: 8,
      }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'].pf_time.length > 0) {
        this.setState({
          pf_time: result['data'].pf_time,
          womac_time: result['data'].womac_time,
          ec_time: result['data'].ec_time,
          who5_time: result['data'].who5_time,
          phq9_time: result['data'].phq9_time,
          gad7_time: result['data'].gad7_time,
          si_time: result['data'].si_time,
          csq8_time: result['data'].csq8_time,
          csq8_program_time: result['data'].csq8_program_time,
          csq8_platform_time: result['data'].csq8_platform_time,
        })
      }
    })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
    <div className="admin_assm">
      <div className="small-12 columns box_assm1 more_padding1">
        <ul className="assm_1">
          <li>
            <a href={'/admin/assessments/promis_pf/'+this.props.match.params.pid+'/w/8'}
            target="_blank">PROMIS Physical Function</a>
            {this.state.pf_time != '' && ' [Completed: '+this.state.pf_time+']'}
          </li>
          <li>
            <a href={'/admin/assessments/womac/'+this.props.match.params.pid+'/w/8'}
            target="_blank">WOMAC</a>
            {this.state.womac_time != '' && ' [Completed: '+this.state.womac_time+']'}
          </li>
          <li>
            <a href={'/admin/assessments/exercise_confidence/'+this.props.match.params.pid+'/w/8'}
            target="_blank">Exercise Confidence</a>
            {this.state.ec_time != '' && ' [Completed: '+this.state.ec_time+']'}
          </li>
          <li>
            <a href={'/admin/assessments/who5/'+this.props.match.params.pid+'/w/8'}
            target="_blank">WHO-5</a>
            {this.state.who5_time != '' && ' [Completed: '+this.state.who5_time+']'}
          </li>
          <li>
            <a href={'/admin/assessments/phq9/'+this.props.match.params.pid+'/w/8'}
            target="_blank">PHQ-9</a>
            {this.state.phq9_time != '' && ' [Completed: '+this.state.phq9_time+']'}
          </li>
          <li>
            <a href={'/admin/assessments/gad7/'+this.props.match.params.pid+'/w/8'}
            target="_blank">GAD-7</a>
            {this.state.gad7_time != '' && ' [Completed: '+this.state.gad7_time+']'}
          </li>
          <li>
            <a href={'/admin/assessments/promis_si/'+this.props.match.params.pid+'/w/8'}
            target="_blank">PROMIS Social Isolation</a>
            {this.state.si_time != '' && ' [Completed: '+this.state.si_time+']'}
          </li>
          <li>
            <a href={'/admin/assessments/csq8_platform/'+this.props.match.params.pid+'/w/8'}
            target="_blank">Client Satisfaction Questionnaire - Platform</a>
            {this.state.csq8_platform_time != '' && ' [Completed: '+this.state.csq8_platform_time+']'}
          </li>
          <li>
            <a href={'/admin/assessments/csq8_program/'+this.props.match.params.pid+'/w/8'}
            target="_blank">Client Satisfaction Questionnaire - Program</a>
            {this.state.csq8_program_time != '' && ' [Completed: '+this.state.csq8_program_time+']'}
          </li>
        </ul>
      </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Week8)
