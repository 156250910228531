import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'

import { baseline_assm_steps, tp_type } from '../../../../variables/baseline_assm.js'
import { followup_assm_steps } from '../../../../variables/followup_assm.js'
import { csq8_questions1, csq8_scale1, csq8_scale2, csq8_scale3,
         csq8_scale4, csq8_scale5, csq8_scale6, csq8_scale7,
         csq8_scale8 } from '../../../../variables/baseline_csq8.js'
import { questions1, scale1, scale2, scale3,
        scale4, scale5, scale6, scale7, scale7_1, scale8, scale9, scale10, scale11,
        questions1_es, scale1_es, scale2_es, questions2, scale21, scale22, scale23,
        scale24, scale25, scale26, scale27,
        scale3_es, scale4_es, scale5_es, scale6_es,
        scale7_es, scale7_1_es, scale8_es  } from '../../../../variables/baseline_exit.js'

class ExitInterview extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        timepoint: 0,
        q1: 0,
        q2: 0,
        q3_1: 0,
        q3_2: 0,
        q3_3: 0,
        q3_4: 0,
        q3_5: 0,
        q3_6: 0,
        q3_7: 0,
        q3_8: 0,
        q3_8_other: '',
        q4: 0,
        q5: 0,
        q6_1: 0,
        q6_2: 0,
        q6_3: 0,
        q6_4: 0,
        q6_5: 0,
        q6_6: 0,
        q6_7: 0,
        q6_7_other: '',
        q7: 0,
        q7_1: 0,
        q7_1_other: '',
        q8: 0,
        q9: 0,
        q10_1: 0,
        q10_2: 0,
        q10_3: 0,
        q10_4: 0,
        q10_5: 0,
        q10_6: 0,
        q10_6_other: '',
        q10_7: 0,
        q11_1: 0,
        q11_2: 0,
        q11_3: 0,
        q11_4: 0,
        q11_5: 0,
        q11_6: 0,
        q11_6_other: '',
        q11_7: 0,
        q21_1: 0,
        q21_2: 0,
        q21_3: 0,
        q21_4: 0,
        q21_5: 0,
        q21_5_other: '',
        q22: 0,
        q23: 0,
        q24: 0,
        q25: 0,
        q26: 0,
        q27: 0,
        comments: '',
        created: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_participant/get_exit_interview',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        type_tp: this.props.match.params.type_tp,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].uid > 0) {
        const get_val = result['data']
        this.setState({
          type_tp: this.props.match.params.type_tp,
          timepoint: this.props.match.params.timepoint,
          created: result['data'].created,
          q1: get_val.q1,
          q2: get_val.q2,
          q3_1: get_val.q3_1,
          q3_2: get_val.q3_2,
          q3_3: get_val.q3_3,
          q3_4: get_val.q3_4,
          q3_5: get_val.q3_5,
          q3_6: get_val.q3_6,
          q3_7: get_val.q3_7,
          q3_8: get_val.q3_8,
          q3_8_other: get_val.q3_8_other,
          q4: get_val.q4,
          q5: get_val.q5,
          q6_1: get_val.q6_1,
          q6_2: get_val.q6_,
          q6_3: get_val.q6_3,
          q6_4: get_val.q6_4,
          q6_5: get_val.q6_5,
          q6_6: get_val.q6_6,
          q6_7: get_val.q6_7,
          q6_7_other: get_val.q6_7_other,
          q7: get_val.q7,
          q7_1: get_val.q7_1,
          q7_1_other: get_val.q7_1_other,
          q8: get_val.q8,
          q9: get_val.q9,
          q10_1: get_val.q10_1,
          q10_2: get_val.q10_2,
          q10_3: get_val.q10_3,
          q10_4: get_val.q10_4,
          q10_5: get_val.q10_5,
          q10_6: get_val.q10_6,
          q10_6_other: get_val.q10_6_other,
          q10_7: get_val.q10_7,
          q11_1: get_val.q11_1,
          q11_2: get_val.q11_2,
          q11_3: get_val.q11_3,
          q11_4: get_val.q11_4,
          q11_5: get_val.q11_5,
          q11_6: get_val.q11_6,
          q11_6_other: get_val.q11_6_other,
          q11_7: get_val.q11_7,
          q21_1: get_val.q21_1,
          q21_2: get_val.q21_2,
          q21_3: get_val.q21_3,
          q21_4: get_val.q21_4,
          q21_5: get_val.q21_5,
          q21_5_other: get_val.q21_5_other,
          q22: get_val.q22,
          q23: get_val.q23,
          q24: get_val.q24,
          q25: get_val.q25,
          q26: get_val.q26,
          q27: get_val.q27,
          comments: get_val.comments,
        })
      }
    })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    const ques1 = questions1
    const csq_scale = [scale1, scale2, scale3, scale4, scale5, scale6, scale7, scale8]
    return (
    <div className="participant1">
        <div className="headerPage3_1 medium-6 columns">
        Exit Questionnaire [{this.findLabelByValue(tp_type, this.props.match.params.type_tp)}]
        </div>
        <div className="medium-6 columns align-right">
        Completed: {this.state.created}
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns">
              <div className="row" >
                <div className="small-12 columns">1. {ques1[0].label}</div>
                {scale1.map(each =>
                <div key={'sc1_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q1_'+each.value}>
                    <input type="radio" name="q1" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state.q1 == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q1')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row" >
                <div className="small-12 columns">2. {ques1[1].label}</div>
                {scale2.map(each =>
                <div key={'sc2_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q2_'+each.value}>
                    <input type="radio" name="q2" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q2'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q2')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row" >
                <div className="small-12 columns">3. {ques1[2].label}</div>
                {scale3.map(each =>
                <div key={'sc3_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q3_'+each.value}>
                    <input type="checkbox" name={'q3_'+each.value} value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q3_'+each.value] == each.value && 'checked'}
                    onChange={(e) => this.handleChange3(e, 'q3_'+each.value)}/>
                    <span>{each.label}</span>
                    {each.value == 8 && this.state.q3_8 == 8 &&
                    <div className="top_space1">{this.state.q3_8_other}</div>}
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row" >
                <div className="small-12 columns">
                  <i>The following questions relate to the online platform you used during this study. Please answer all of the questions. Your feedback will help to make this platform better for others. Thank you!</i>
                </div>
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">4. {ques1[3].label}</div>
                {scale4.map(each =>
                <div key={'sc4_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q4_'+each.value}>
                    <input type="radio" name="q4" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q4'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q4')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">5. {ques1[4].label}</div>
                {scale5.map(each =>
                <div key={'sc5_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q5_'+each.value}>
                    <input type="radio" name="q5" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q5'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q5')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              {(this.state.q5 == 3 || this.state.q5 == 4) &&
              <>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row" >
                <div className="small-12 columns">6. {ques1[5].label}</div>
                {scale6.map(each =>
                <div>
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q6_'+each.value}>
                    <input type="checkbox" name={'q6_'+each.value} value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q6_'+each.value] == each.value && 'checked'}
                    onChange={(e) => this.handleChange3(e, 'q6_'+each.value)}/>
                    <span>{each.label}</span>
                    {each.value == 7 && this.state.q6_7 == 7 &&
                    <div className="top_space1">{this.state.q6_7_other}</div>}
                  </div>
                </div>)}
              </div>
              </>}
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">7. {ques1[6].label}</div>
                {scale7.map(each =>
                <div key={'sc7_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q7_'+each.value}>
                    <input type="radio" name="q7" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q7'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q7')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              {this.state.q7 == 1 &&
              <><div className="row top_space1" >
                  <div className="small-12 columns">What would feel like the right amount of support to use the platform?</div>
                  {scale7_1.map(each =>
                  <div key={'sc71_'+each.value} >
                    <div className="show-for-medium medium-1 columns">&nbsp;</div>
                    <div className="small-12 medium-11 columns top_space2" key={'q7_1'+each.value}>
                      <input type="radio" name="q7_1" value={each.value}
                      disabled={this.state.completed_status && 'disabled'} className="rad1"
                      checked={this.state['q7_1'] == each.value && 'checked'}
                      onChange={(e) => this.handleChange2(e, 'q7_1')}/>
                      <span>{each.label}</span>
                      {each.value == 4 && this.state.q7_1 == 4 &&
                      <div className="top_space1">{this.state.q7_1_other}</div>}
                    </div>
                  </div>)}
                </div>
                <div className="small-12 columns sep_line1">&nbsp;</div>
              </>}

              <div className="row top_space1" >
                <div className="small-12 columns">8. {ques1[7].label}</div>
                {scale8.map(each =>
                <div key={'sc8_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q8_'+each.value}>
                    <input type="radio" name="q8" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q8'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q8')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">9. {ques1[8].label}</div>
                {scale9.map(each =>
                <div key={'sc9_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q9_'+each.value}>
                    <input type="radio" name="q9" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q9'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q9')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row" >
                <div className="small-12 columns">10. {ques1[9].label}</div>
                {scale10.map(each =>
                <div key={'sc10_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q10_'+each.value}>
                    <input type="checkbox" name={'q10_'+each.value} value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q10_'+each.value] == each.value && 'checked'}
                    onChange={(e) => this.handleChange3(e, 'q10_'+each.value)}/>
                    <span>{each.label}</span>
                    {each.value == 6 && this.state.q10_6 == 6 &&
                    <div className="top_space1">{this.state.q10_6_other}</div>}
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row" >
                <div className="small-12 columns">11. {ques1[10].label}</div>
                {scale11.map(each =>
                <div key={'sc11_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q11_'+each.value}>
                    <input type="checkbox" name={'q11_'+each.value} value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q11_'+each.value] == each.value && 'checked'}
                    onChange={(e) => this.handleChange3(e, 'q11_'+each.value)}/>
                    <span>{each.label}</span>
                    {each.value == 6 && this.state.q11_6 == 6 &&
                    <div className="top_space1">{this.state.q11_6_other}</div>}
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row" >
                <div className="small-12 columns bold">Technology</div>
                <div className="small-12 columns top_space1">1. {questions2[0].label}</div>
                {scale21.map(each =>
                <div key={'sc21_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q21_'+each.value}>
                    <input type="checkbox" name={'q21_'+each.value} value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q21_'+each.value] == each.value && 'checked'}
                    onChange={(e) => this.handleChange3(e, 'q21_'+each.value)}/>
                    <span>{each.label}</span>
                    {each.value == 5 && this.state.q21_5 == 5 &&
                    <div className="top_space1">{this.state.q21_5_other}</div>}
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">2. {questions2[1].label}</div>
                {scale22.map(each =>
                <div key={'sc22_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q22_'+each.value}>
                    <input type="radio" name="q22" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q22'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q22')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">3. {questions2[2].label}</div>
                {scale22.map(each =>
                <div key={'sc221_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q23_'+each.value}>
                    <input type="radio" name="q23" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q23'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q23')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">4. {questions2[3].label}</div>
                {scale22.map(each =>
                <div key={'sc223_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q24_'+each.value}>
                    <input type="radio" name="q24" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q24'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q24')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">5. {questions2[4].label}</div>
                {scale22.map(each =>
                <div key={'sc224_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q25_'+each.value}>
                    <input type="radio" name="q25" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q25'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q25')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">6. {questions2[5].label}</div>
                {scale22.map(each =>
                <div key={'sc225_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q26_'+each.value}>
                    <input type="radio" name="q26" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q26'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q26')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="row top_space1" >
                <div className="small-12 columns">7. {questions2[6].label}</div>
                {scale22.map(each =>
                <div key={'sc226_'+each.value} >
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'q27_'+each.value}>
                    <input type="radio" name="q27" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q27'] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q27')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
              </div>

              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="small-12 columns">
                What comments, feedback do you have about the technology?
              </div>
              <div className="small-12 columns top_space1">
              {this.state.comments}
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns back_button1">
          <a href={'/admin/participant_chart/'+this.props.match.params.pid+'/2/2'}>Back</a>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ExitInterview)
