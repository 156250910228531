import React, { Component } from 'react'
import Image from '../../images/banner_about2.jpg'

class About extends Component {
  render() {
    return (
      <div className="about">
        <div className="banner">
          <img src={Image} alt="about_banner" />
        </div>
        <div className="wrapper row">
          <div className="small-12 columns">
            <div className="title">
              About Us
            </div>
            <div className="content">
            <p>MoodNetwork’s team is based at Massachusetts General Hospital in Boston and has years of experience collecting and storing clinical data for research studies. We know how to protect your privacy and preserve your anonymity. Our security systems are state-of-the-art. The information you give us will be used only to better understand and treat mood disorders. We will never sell, rent, or lease your information.</p>

            <p>We make sure that only authorized clinicians involved in this study will be able to see the data that you enter into MoodNetwork. No one else will ever be able to link your health information with your username.</p>

            <p>If researchers use data from the MoodNetwork study, they will only see your information combined with that of other MoodNetwork participants. None of this data will include anything specific that can be linked to you.</p>

            <p>We also make sure that no unauthorized people will be able to see your information. Everything you share with us is securely stored in a database behind Massachusetts General Hospital’s firewall. We scan and monitor all systems regularly to prevent unauthorized access.</p>

            <p>To see your own health information, you will have to log in to MoodNetwork with a secure username and password. You must be very careful not to give anyone else your login information. If you are concerned at any time that someone may have your username or password, you should let us know immediately.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
