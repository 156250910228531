import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../utils/global'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { FaRegUserCircle } from 'react-icons/fa'
//import { loadAuthUser } from '../../actions/auth'
import { loadLocationWithMessage } from '../../actions/location'
import validator from 'validator'

class ResetPassword extends Component {
  constructor() {
      super();
      this.state = {
        status_submit: true,
        status_existed: true,
        new_password: '',
        new_password: '',
        re_new_password: ''
      }
      this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    // check if already logged in //
    if(this.props.authReducer) {
      window.location.href = "/user/settings"
    }
    // Check existed token //
    axios({
      method: 'post',
      url: connectNode + 'user/check_existed_token',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        token: this.props.match.params.token,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data']) {
        if(result['data'].status == 2) {
          this.setState({
            status_msg: 1,
            message: 'You have already used this link to change password.',
            status_existed: false,
          })
        } else {
          this.setState({
            id: result['data'].id,
            uid: result['data'].uid,
          })
        }
      } else {
        this.setState({
          status_msg: 1,
          message: 'Sorry, we couldn\'t find your information',
          status_existed: false,
        })
      }
    })
  }

  handleChange = (e) => {
    this.setState({
        [e.target.name]: e.target.value
    })
  }

  handleChangePassword(event) {
    var password = event.target.value
    if(password.length > 0) {
      document.getElementById('password_policy').style.display = 'block'
    } else {
      document.getElementById('password_policy').style.display = 'none'
    }

    if (password.match(/[a-z]+/)) {
      document.getElementById('lower_case').style.color = '#1a8f00'
    } else {
      document.getElementById('lower_case').style.color = '#cf0000'
    }

    if (password.match(/[A-Z]+/)) {
      document.getElementById('upper_case').style.color = '#1a8f00'
    } else {
      document.getElementById('upper_case').style.color = '#cf0000'
    }
    if (password.match(/[0-9]+/)) {
      document.getElementById('one_number').style.color = '#1a8f00'
    } else {
      document.getElementById('one_number').style.color = '#cf0000'
    }

    if (password.length >= 8) {
      document.getElementById('eight_char').style.color = '#1a8f00'
    } else {
      document.getElementById('eight_char').style.color = '#cf0000'
    }

    this.setState({
      [event.target.name]: event.target.value
    })
  }

  validatePasswordStrength(password) {
    var strength = 0
    if(password.length < 8 || !password.match(/[a-z]+/) ||
      !password.match(/[A-Z]+/) || !password.match(/[0-9]+/)) {
      strength = 2
    }
    return strength
  }

  handleSubmit = (e) => {
      e.preventDefault()
      //const { dispatch } = this.props;
      if(this.state.new_password == '' || this.state.re_new_password == '') {
        this.setState({
          status_msg: 1,
          message: 'Please provide new password',
        })
      } else {
        if(this.state.new_password !== this.state.re_new_password) {
          this.setState({
            status_msg: 1,
            message: 'Please enter the same password.',
          })
        } else {
          if(this.validatePasswordStrength(this.state.new_password) === 2) {
            this.setState({
              status_msg: 1,
              message: 'Please enter the strength password.',
            })
          } else {
            axios({
              method: 'post',
              url: connectNode + 'user/reset_password',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
              },
              data: {
                uid: this.state.uid,
                user: this.state,
              }
            })
            .then((result) => {
                if(result['data'] == this.state.uid) {
                  this.props.onLoadLocation('login', 'Changed password successfully!')
                  this.props.history.push('/login')
                } else {
                  if(result['data'] == -4) {
                    this.setState({
                      status_msg: 1,
                      message: 'Cannot reuse four (4) previous passwords',
                    })
                  } else {
                    this.setState({
                      status_msg: 1,
                      message: 'Something went wrong. Plase try again.',
                    })
                  }
                }
              }
            )
          }
        }
      }
  }

  render() {
    return (
      !this.props.authReducer &&
      <div className="row forgot_password" id="wrapper-login">
        <div className="small-12 columns">
          <div className="title">Reset Password</div>
        </div>
        <div className="small-12 columns">
          { this.state.message !== '' && this.state.status_msg == 1 &&
            <div className="warning_msg6">
              <div className="float_left1"><WarningIcon /></div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>}
          { this.state.message !== '' && this.state.status_msg == 2 &&
            <div className="warning_msg8">
              <div className="float_left1"><CheckCircleOutlineIcon /></div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>}
          {this.state.status_existed &&
          <form id="forgot_password" onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="small-12 columns title1">New password</div>
              <div className="small-12 columns float_left">
                <TextField onChange={(e) => this.handleChangePassword(e)}
                variant="outlined"
                type="password" defaultValue={this.state.new_password}
                InputProps={{ inputProps: { name: "new_password" }}} />
              </div>
            </div>

            <div className="row">
              <div className="small-12 columns">
                <div className="password_policy top_space1" id="password_policy">
                  <div className="bold">Password strength:</div>
                  <div className=""><ul>
                  <li id="lower_case">One lowercase character</li>
                  <li id="upper_case">One uppercase character</li>
                  <li id="one_number">One number</li>
                  <li id="eight_char">8 characters minimum</li>
                  </ul></div>
                </div>
              </div>
            </div>

            <div className="row top_space1">
              <div className="small-12 columns title1">Retype new password</div>
              <div className="small-12 columns float_left">
                <TextField onChange={this.handleChange} variant="outlined"
                type="password" defaultValue={this.state.re_new_password}
                InputProps={{ inputProps: { name: "re_new_password" }}} />
              </div>
            </div>

            <div className="row top_space1">
              <div className="small-12 columns">
              {this.state.status_submit &&
                <Button variant="contained" color="primary" className="button"
                onClick={this.handleSubmit} type="submit">Submit</Button>}
              {!this.state.status_submit &&
                <Button variant="contained" disabled className="button"
                onClick={this.handleSubmit}>Submit</Button>}
              </div>
            </div>
          </form>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Login STATE: ', state)
  //return state
  return {
    txt_message: state.locationReducer.txt_message,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Login mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    //console.log('header: '+route)
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ResetPassword)
//export default Login
