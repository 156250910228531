import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'

import { baseline_assm_steps, tp_type } from '../../../../variables/baseline_assm.js'
import { followup_assm_steps } from '../../../../variables/followup_assm.js'
import { ecfu_scale1, csq8_questions1, ecfu_scale2 } from '../../../../variables/baseline_exercise_follow_up.js'

class ExerciseClassFollowUp extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        timepoint: 0,
        survey_date: '',
        q1: 0,
        q2_1: 0,
        q2_2: 0,
        q2_3: 0,
        q2_4: 0,
        q2_5: 0,
        q2_6: 0,
        q2_7: 0,
        q2_8: 0,
        q2_9: 0,
        q2_10: 0,
        q2_11: 0,
        q2_12: 0,
        q2_12_specify: '',
        q3: '',
        created: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_participant/get_ecfu',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        type_tp: this.props.match.params.type_tp,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
      if(result['data'].uid > 0) {
        const get_val = result['data']
        this.setState({
          type_tp: this.props.match.params.type_tp,
          timepoint: this.props.match.params.timepoint,
          created: result['data'].created,
          q1: get_val.q1,
          q2_1: get_val.q2_1,
          q2_2: get_val.q2_2,
          q2_3: get_val.q2_3,
          q2_4: get_val.q2_4,
          q2_5: get_val.q2_5,
          q2_6: get_val.q2_6,
          q2_7: get_val.q2_7,
          q2_8: get_val.q2_8,
          q2_9: get_val.q2_9,
          q2_10: get_val.q2_10,
          q2_11: get_val.q2_11,
          q2_12: get_val.q2_12,
          q2_12_specify: get_val.q2_12_specify,
          q3: get_val.q3,
        })
      }
    })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
    <div className="participant1">
        <div className="headerPage3_1 medium-6 columns">
        Exercise Class Follow-Up [{this.findLabelByValue(tp_type, this.props.match.params.type_tp)}]
        </div>
        <div className="medium-6 columns align-right">
        Completed: {this.state.created}
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns">
              <div className="row">
                <div className="small-12 medium-8 columns">
                Since beginning the HAIL program, have you participated in any other exercise classes?
                </div>
                {ecfu_scale1.map(each =>
                <div className="small-12 medium-2 columns">
                  <div className="small-12 columns top_space2" key={'qs1'+each.value}>
                    <input type="radio" name="q1" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state.q1 == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q1')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 medium-6 columns">Which type of class did you participate in?</div>
                <div className="small-12 medium-6 columns">
                {ecfu_scale2.map(each =>
                <div className="small-12 columns">
                  <div className="small-12 columns top_space2" key={'qs1'+each.value}>
                    <input type="checkbox" name={"q2_"+each.value} value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state["q2_"+each.value] == each.value && 'checked'}
                    onChange={(e) => this.handleChangeQ2(e, "q2_"+each.value)}/>
                    <span>{each.label}</span>
                  </div>
                  {each.value == 12 && this.state.q2_12 == 12 && this.state.q2_12_specify}
                </div>)}
                </div>
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 medium-8 columns">
                  Approximately how many times have you done these classes since the HAIL program started?
                </div>
                <div className="small-12 medium-4 columns">{this.state.q3}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns back_button1">
          <a href={'/admin/participant_chart/'+this.props.match.params.pid+'/2/2'}>Back</a>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ExerciseClassFollowUp)
