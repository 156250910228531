export const questions1 = [
  {
    label: 'Overall, how would you rate the quality of the exercise program?',
    value: 1,
  }, {
    label: 'Overall, how easy or difficult was it for you to participate in the exercise program?',
    value: 2,
  }, {
    label: 'If answer to 2 is somewhat or very difficult: What did you have difficulty with? Please check all that apply.',
    value: 3,
  }, {
    label: 'Overall, how would you rate the quality of the online platform?',
    value: 4,
  }, {
    label: "Overall, how easy or difficult was it for you to use the platform?",
    value: 5,
  }, {
    label: 'If answer to 2 is somewhat or very difficult: What did you have difficulty with? Please check all that apply.',
    value: 6,
  }, {
    label: 'Did you want more support from study staff on how to use the platform?',
    value: 7,
  }, {
    label: 'How helpful was the platform in motivating you to exercise?',
    value: 8,
  }, {
    label: 'How effective was the platform at keeping you engaged with exercise?',
    value: 9,
  }, {
    label: 'What did you like most about the platform? Check all that apply.',
    value: 10,
  }, {
    label: 'What did you like least about the platform? Check all that apply.',
    value: 11,
  },
]

export const questions1_es = [
  {
    label: 'Overall, how would you rate the quality of the exercise program?',
    value: 1,
  }, {
    label: 'Overall, how easy or difficult was it for you to participate in the exercise program?',
    value: 2,
  }, {
    label: 'If answer to 2 is somewhat or very difficult: What did you have difficulty with? Please check all that apply.',
    value: 3,
  }, {
    label: 'Overall, how would you rate the quality of the online platform?',
    value: 4,
  }, {
    label: "Overall, how easy or difficult was it for you to use the platform?",
    value: 5,
  }, {
    label: 'If answer to 2 is somewhat or very difficult: What did you have difficulty with? Please check all that apply.',
    value: 6,
  }, {
    label: 'Did you want more support from study staff on how to use the platform?',
    value: 7,
  }, {
    label: 'How helpful was the platform in motivating you to exercise?',
    value: 8,
  }, {
    label: 'How effective was the platform at keeping you engaged with exercise?',
    value: 9,
  }, {
    label: 'What did you like most about the platform? Check all that apply.',
    value: 10,
  }, {
    label: 'What did you like least about the platform? Check all that apply.',
    value: 11,
  },
]

export const scale1 = [
  {
    label: 'Excellent',
    value: 1,
  }, {
    label: 'Good',
    value: 2,
  }, {
    label: 'Fair',
    value: 3,
  }, {
    label: 'Poor',
    value: 4,
  },
]

export const scale1_es = [
  {
    label: 'Excellent',
    value: 1,
  }, {
    label: 'Good',
    value: 2,
  }, {
    label: 'Fair',
    value: 3,
  }, {
    label: 'Poor',
    value: 4,
  },
]

export const scale2 = [
  {
    label: 'Very easy',
    value: 1,
  }, {
    label: 'Fairly easy',
    value: 2,
  }, {
    label: 'Somewhat difficult',
    value: 3,
  }, {
    label: 'Very difficult',
    value: 4,
  },
]

export const scale2_es = [
  {
    label: 'Very easy',
    value: 1,
  }, {
    label: 'Fairly easy',
    value: 2,
  }, {
    label: 'Somewhat difficult',
    value: 3,
  }, {
    label: 'Very difficult',
    value: 4,
  },
]

export const scale3 = [
  {
    label: 'The exercise was too hard',
    value: 1,
  }, {
    label: 'The schedule was too hard (i.e., meeting 3x per week for 8 weeks)',
    value: 2,
  }, {
    label: 'I did not like the location/space',
    value: 3,
  }, {
    label: 'I did not like the exercise equipment (e.g., bands, weight)',
    value: 4,
  }, {
    label: 'I did not like the exercise manual (e.g., hard to understand, too much information)',
    value: 5,
  }, {
    label: 'I did not like the exercise instructor',
    value: 6,
  }, {
    label: 'Practicing the exercises on my own',
    value: 7,
  }, {
    label: 'Other ',
    value: 8,
  },
]

export const scale3_es = [
  {
    label: 'The exercise was too hard',
    value: 1,
  }, {
    label: 'The schedule was too hard (i.e., meeting 3x per week for 8 weeks)',
    value: 2,
  }, {
    label: 'I did not like the location/space',
    value: 3,
  }, {
    label: 'I did not like the exercise equipment (e.g., bands, weight)',
    value: 4,
  }, {
    label: 'I did not like the exercise manual (e.g., hard to understand, too much information)',
    value: 5,
  }, {
    label: 'I did not like the exercise instructor',
    value: 6,
  }, {
    label: 'Practicing the exercises on my own',
    value: 7,
  }, {
    label: 'Other ',
    value: 8,
  },
]

export const scale4 = [
  {
    label: 'Excellent',
    value: 1,
  }, {
    label: 'Good',
    value: 2,
  }, {
    label: 'Fair',
    value: 3,
  }, {
    label: 'Poor',
    value: 4,
  },
]

export const scale4_es = [
  {
    label: 'Excellent',
    value: 1,
  }, {
    label: 'Good',
    value: 2,
  }, {
    label: 'Fair',
    value: 3,
  }, {
    label: 'Poor',
    value: 4,
  },
]

export const scale5 = [
  {
    label: 'Very easy',
    value: 1,
  }, {
    label: 'Fairly easy',
    value: 2,
  }, {
    label: 'Somewhat difficult',
    value: 3,
  }, {
    label: 'Very difficult',
    value: 4,
  },
]

export const scale5_es = [
  {
    label: 'Very easy',
    value: 1,
  }, {
    label: 'Fairly easy',
    value: 2,
  }, {
    label: 'Somewhat difficult',
    value: 3,
  }, {
    label: 'Very difficult',
    value: 4,
  },
]

export const scale6 = [
  {
    label: 'Using the dashboard (i.e., knowing where to find things)',
    value: 1,
  }, {
    label: 'Logging onto the online exercise classes',
    value: 2,
  }, {
    label: 'Filling out questionnaires',
    value: 3,
  }, {
    label: 'Filling out the exercise log',
    value: 4,
  }, {
    label: 'Reaching study staff for help',
    value: 5,
  }, {
    label: 'Understanding the weekly instructor’s tip',
    value: 6,
  }, {
    label: 'Other ',
    value: 7,
  },
]

export const scale6_es = [
  {
    label: 'Using the dashboard (i.e., knowing where to find things)',
    value: 1,
  }, {
    label: 'Logging onto the online exercise classes',
    value: 2,
  }, {
    label: 'Filling out questionnaires',
    value: 3,
  }, {
    label: 'Filling out the exercise log',
    value: 4,
  }, {
    label: 'Reaching study staff for help',
    value: 5,
  }, {
    label: 'Understanding the weekly instructor’s tip',
    value: 6,
  }, {
    label: 'Other ',
    value: 7,
  },
]

export const scale7 = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  },
]

export const scale7_es = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  },
]

export const scale7_1 = [
  {
    label: 'Up to an hour of online or in-person assistance at the beginning',
    value: 1,
  }, {
    label: 'Over an hour of online or in-person assistance at the beginning',
    value: 2,
  }, {
    label: 'Ongoing support throughout the study (e.g., weekly check-ins)',
    value: 3,
  }, {
    label: 'Other ',
    value: 4,
  },
]

export const scale7_1_es = [
  {
    label: 'Up to an hour of online or in-person assistance at the beginning',
    value: 1,
  }, {
    label: 'Over an hour of online or in-person assistance at the beginning',
    value: 2,
  }, {
    label: 'Ongoing support throughout the study (e.g., weekly check-ins)',
    value: 3,
  }, {
    label: 'Other ',
    value: 4,
  },
]

export const scale8 = [
  {
    label: 'Extremely helpful',
    value: 1,
  }, {
    label: 'Very helpful',
    value: 2,
  }, {
    label: 'Somewhat helpful',
    value: 3,
  }, {
    label: 'Not at all helpful',
    value: 4,
  },
]

export const scale8_es = [
  {
    label: 'Extremely helpful',
    value: 1,
  }, {
    label: 'Very helpful',
    value: 2,
  }, {
    label: 'Somewhat helpful',
    value: 3,
  }, {
    label: 'Not at all helpful',
    value: 4,
  },
]

export const scale9 = [
  {
    label: 'Extremely effective',
    value: 1,
  }, {
    label: 'Very effective',
    value: 2,
  }, {
    label: 'Somewhat effective',
    value: 3,
  }, {
    label: 'Not at all effective',
    value: 4,
  },
]

export const scale9_es = [
  {
    label: 'Extremely effective',
    value: 1,
  }, {
    label: 'Very effective',
    value: 2,
  }, {
    label: 'Somewhat effective',
    value: 3,
  }, {
    label: 'Not at all effective',
    value: 4,
  },
]

export const scale10 = [
  {
    label: 'Quote of the week',
    value: 1,
  }, {
    label: 'Instructor’s tip of the week',
    value: 2,
  }, {
    label: 'Other participants’ interviews or stories',
    value: 3,
  }, {
    label: 'Exercise Log',
    value: 4,
  }, {
    label: 'Graphics and design',
    value: 5,
  }, {
    label: 'Other ',
    value: 6,
  }, {
    label: 'None',
    value: 7,
  },
]

export const scale10_es = [
  {
    label: 'Quote of the week',
    value: 1,
  }, {
    label: 'Instructor’s tip of the week',
    value: 2,
  }, {
    label: 'Other participants’ interviews or stories',
    value: 3,
  }, {
    label: 'Exercise Log',
    value: 4,
  }, {
    label: 'Graphics and design',
    value: 5,
  }, {
    label: 'Other ',
    value: 6,
  }, {
    label: 'None',
    value: 7,
  },
]

export const scale11 = [
  {
    label: 'Quote of the week',
    value: 1,
  }, {
    label: 'Instructor’s tip of the week',
    value: 2,
  }, {
    label: 'Other participants’ interviews or stories',
    value: 3,
  }, {
    label: 'Exercise Log',
    value: 4,
  }, {
    label: 'Graphics and design',
    value: 5,
  }, {
    label: 'Other ',
    value: 6,
  }, {
    label: 'None',
    value: 7,
  },
]

export const scale11_es = [
  {
    label: 'Quote of the week',
    value: 1,
  }, {
    label: 'Instructor’s tip of the week',
    value: 2,
  }, {
    label: 'Other participants’ interviews or stories',
    value: 3,
  }, {
    label: 'Exercise Log',
    value: 4,
  }, {
    label: 'Graphics and design',
    value: 5,
  }, {
    label: 'Other ',
    value: 6,
  }, {
    label: 'None',
    value: 7,
  },
]

export const questions2 = [
  {
    label: 'What type of device(s) did you use to use the HAIL online platform? (Please select all that apply.)',
    value: 1,
  }, {
    label: 'I was able to clearly see everything on my screen',
    value: 2,
  }, {
    label: 'I was able to clearly hear everything through my device',
    value: 3,
  }, {
    label: 'The platform was easy to use',
    value: 4,
  }, {
    label: "I had a lot of technology problems",
    value: 5,
  }, {
    label: 'The training before the class started prepared me to use the technology',
    value: 6,
  }, {
    label: 'The technical support provided was helpful',
    value: 7,
  },
]

export const questions2_es = [
  {
    label: 'What type of device(s) did you use to use the HAIL online platform? (Please select all that apply.)',
    value: 1,
  }, {
    label: 'I was able to clearly see everything on my screen',
    value: 2,
  }, {
    label: 'I was able to clearly hear everything through my device',
    value: 3,
  }, {
    label: 'The platform was easy to use',
    value: 4,
  }, {
    label: "I had a lot of technology problems",
    value: 5,
  }, {
    label: 'The training before the class started prepared me to use the technology',
    value: 6,
  }, {
    label: 'The technical support provided was helpful',
    value: 7,
  },
]

export const scale21 = [
  {
    label: 'Smartphone',
    value: 1,
  }, {
    label: 'Tablet/iPad',
    value: 2,
  }, {
    label: 'Laptop computer',
    value: 3,
  }, {
    label: 'Desktop computer',
    value: 4,
  }, {
    label: 'Other ',
    value: 5,
  },
]

export const scale21_es = [
  {
    label: 'Smartphone',
    value: 1,
  }, {
    label: 'Tablet/iPad',
    value: 2,
  }, {
    label: 'Laptop computer',
    value: 3,
  }, {
    label: 'Desktop computer',
    value: 4,
  }, {
    label: 'Other ',
    value: 5,
  },
]

export const scale22 = [
  {
    label: 'Strongly Agree',
    value: 5,
  }, {
    label: 'Agree',
    value: 4,
  }, {
    label: 'Neither Agree nor Disagree',
    value: 3,
  }, {
    label: 'Disagree',
    value: 2,
  }, {
    label: 'Strongly Disagree',
    value: 1,
  },
]

export const scale22_es = [
  {
    label: 'Strongly Agree',
    value: 5,
  }, {
    label: 'Agree',
    value: 4,
  }, {
    label: 'Neither Agree nor Disagree',
    value: 3,
  }, {
    label: 'Disagree',
    value: 2,
  }, {
    label: 'Strongly Disagree',
    value: 1,
  },
]
